import styles from "./LoginPage.module.css";
import React, { useEffect } from 'react';
import { signInWithGooglePopup, createUser, db } from '../../hooks/Firebase';
import { useNavigate } from "react-router-dom";
import { Timestamp, doc, setDoc } from "firebase/firestore";
import background from './images/background.svg';
import googleLogin from './images/googleLogin.svg';
import kakaoLogin from './images/kakaoLogin.svg';

// 카카오 로그인 기능 - 1. 가입한 경우에 대한 방지 -> 이메일 필요

const LoginPage = () => {
  const movePage = useNavigate();
  /*
  useEffect(() => {
    if (!window.Kakao.isInitialized()) {
      window.Kakao.init('52e2d211e1ad995fa2cbdb272365720c');
    } else {
      console.error("kakao was not initialized");
    }
  }, []);
  */
  const handleGoogleLogin = async () => { 
    const response = await signInWithGooglePopup();
    const uid = response.user.uid;
    sessionStorage.setItem('userID', uid);

    if (uid != null) {
      try {
        await setDoc(doc(db, "users", uid), {
          displayName: response.user.displayName,
          email: response.user.email,
          creationTime: response.user.metadata.creationTime,
          lastSignInTime: response.user.metadata.lastSignInTime,
        });
      } catch (e) {
        console.error("Error adding document: ", e);
      }
      movePage('/');
    } else {
      console.error("login error");
    }
  }
  /*
  const handleKaKaoLogin = async () => {
    window.Kakao.Auth.login({
      success: () => {
        window.Kakao.API.request({
          url: '/v2/user/me',
          success: async (res) => {
            const user = {
              email: "jungyg22@kakao.com",
              name: res.properties.nickname,
            };
            console.log("user: ", user);
            await setFirebaseAuth(user); 
            await setFirestore(user);  
            movePage('/'); 
          },
          fail: (error) => {
            console.error(error);
          },
        });
      },
      fail: (error) => {
        console.error(error);
      },
    });
  };
  */
  const generateRandomPassword = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let password = '';
    for (let i = 0; i < length; i++) {
      password += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return password;
  }  
  /*
  const setFirebaseAuth = async (user) => {
    const randomPassword = generateRandomPassword(12);
    await createUser(user.email, randomPassword)
    .then((userCredential) => {
      console.log("userID: ", userCredential.user.uid);
      sessionStorage.setItem('userID', userCredential.user.uid);
    })
    .catch((error) => {
      console.log("Error setting auth: ", error);
    });
  }

  const setFirestore = async (user) => {
    const sessionId = sessionStorage.getItem('userID');
    console.log("sessionId: ", sessionId);
    if (sessionId !== null) {
      try {
        await setDoc(doc(db, "users", sessionId), {
          displayName: user.name,
          email: user.email,
          creationTime: Timestamp.now(),
          lastSignInTime: Timestamp.now(),
        });
        console.log("Document written with ID: ", sessionId);
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    } else {
      console.error("login error");
    }
  }
  */
  return (
    <div className={styles.container}>
      <div className={styles.login}>
      <img className={styles.login}src={background} width={353} height={389} />
      <div className={styles.google}>
        <button className={styles.google} onClick={handleGoogleLogin}>
          <img className={styles.google} src={googleLogin} width={358} height={49} />
        </button>
      </div>

    </div>
    </div>
    
  );
}

export default LoginPage
