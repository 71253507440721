import styles from './Menu.module.css';
import { ReactComponent as Line } from "./images/line.svg";

const Menu = ({
  isModalOpen,
  company,
  position,
  textAreaContent,
  handleTextAreaChange,
  currentCharCount,
  charLimit,
  handleCharLimit,
  isInputValuePresent,
  currentStage,
  selectedType,
  selectedSubType,
  getData
}) => {
  return (
    <div className={styles.input_container}>
      <p className={styles.input_company}>{isModalOpen ? '토스뱅크' : company}</p>
      <p className={styles.input_position}>{isModalOpen ? 'Content Marketing Assistant' : position}</p>
      <Line className={styles.input_line} width={640}/>
      <div className={styles.input_header}>
        <div className={styles.header_intro_wrapper}>
          <input className={styles.header_intro} type="text" placeholder={'문항을 입력해주세요.'}></input>
        </div>
        <p className={styles.header_caution}>* 표시된 사항은 필수 입력</p>
      </div>
      <div className={styles.input_menu}>
        <p className={styles.menu_title}>자기소개서</p>
        <textarea className={styles.menu_textarea} placeholder="내용을 입력해주세요." rows={12} value={textAreaContent} onChange={(e) => handleTextAreaChange(e)} />
      </div>
      <div className={styles.input_footer}>
        <div className={styles.footer_number}>
          <p className={styles.number_intro}>글자수 입력</p>
          <p className={styles.number_current}>{currentCharCount}</p>
          <p> / </p>
          <div className={styles.number_limit}>
            <input className={styles.limit_input} value={charLimit} onChange={(e) => handleCharLimit(e)}></input>
          </div>
        </div>
        <div className={styles.footer_button} style={{ background: isInputValuePresent && currentStage > 0 && currentStage < 5 ? null : '#D9D9D9' }}>
          <button className={styles.footer_button_text} onClick={isInputValuePresent && currentStage > 0 && currentStage < 5 ? () => getData(selectedType, selectedSubType, currentStage) : null}>피드백 받기</button>
        </div>
      </div>
    </div>
  );
}

export default Menu;
