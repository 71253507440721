import styles from './Feedback.module.css'
import up_arrow from "./images/uparrow.svg";
import down_arrow from "./images/downarrow.svg";
import LottieLoader from './util/LottieLoader';
import check_1 from './images/check_1.svg';
import check_2 from './images/check_2.svg';
import check_3 from './images/check_3.svg';

const FeedbackStageZero = ({
  isTypeSelected,
  selectedType,
  CATEGORY_TYPES,
  toggleCategoryType,
  handleCategoryType,
  isSubTypeSelected,
  selectedSubType,
  CATEGORY_SUBTYPES,
  toggleCategorySubType,
  handleCategorySubType,
  isReady,
  coreValues,
  handleCoreValuesChange,
  getData,
}) => {
  const isCoreValuePresent = coreValues.length > 0;

  return (
    <div className={styles.feedback_container}>
      <p className={styles.feedback_title}>문항 카테고리 선택</p>
      {isTypeSelected ? (
        <div className={styles.category_container_after_selection}>
          <button className={styles.category_after_selection} onClick={toggleCategoryType}>
            <p className={styles.category_after_selection} style={{ color: '#2248B8' }}>{selectedType}</p>
            <img src={up_arrow} />
          </button>
          {Object.values(CATEGORY_TYPES).map(category => (
            <button className={styles.category_after_selection} onClick={() => handleCategoryType(category)}>
              <p className={styles.category_after_selection}>{category}</p>
            </button>
          ))}
        </div>
      ) : (
        <div className={styles.category_container_before_selection}>
          <button className={styles.category_before_selection} onClick={toggleCategoryType}>
            <p className={styles.category_before_selection}>{selectedType}</p>
            <img src={down_arrow} />
          </button>
        </div>
      )}
      <div className={styles.feedback_content_container} style={{ marginTop: '32px' }}>
        {selectedType === CATEGORY_TYPES.APPLICATION_MOTIVATION ? (
          <>
            <p style={{ position: 'relative', top: '24px' }}>
              지원자님께서 직무 희망 이유와 관련 경험을 쉽게 떠올리실 수 있게 채용공고 내용과 <br />
              자체 데이터로 만든 참고자료를 제공해드려요.
             
            </p>
            {isSubTypeSelected ? (
              <div className={styles.sub_category_container_after_selection}>
                <button className={styles.category_after_selection} onClick={toggleCategorySubType}>
                  <p className={styles.category_after_selection} style={{ color: '#2248B8' }}>{selectedSubType}</p>
                  <img src={up_arrow} />
                </button>
                {Object.values(CATEGORY_SUBTYPES).map(category => (
                  <button className={styles.sub_category_after_selection} onClick={() => handleCategorySubType(category)}>
                    <p className={styles.sub_category_after_selection}>{category}</p>
                  </button>
                ))}
              </div>
            ) : (
              <>
                <div className={styles.sub_category_container_before_selection}>
                  <button className={styles.sub_category_before_selection} onClick={toggleCategorySubType}>
                    <p className={styles.sub_category_before_selection}>{selectedSubType}</p>
                    <img src={down_arrow} />
                  </button>
                </div>
                {selectedSubType === CATEGORY_SUBTYPES.INDUSTRY_MOTIVATION ? (
                  <p style={{ position: 'relative', top: '24px' }}>좋습니다! 연구개발직무 관련 참고자료를 작성해보겠습니다!</p>
                ) : selectedSubType === CATEGORY_SUBTYPES.COMPANY_MOTIVATION ? (
                  <p style={{ position: 'relative', top: '24px' }}>좋습니다! 회사지원동기 함께 작성해보겠습니다!</p>
                ) : selectedSubType === CATEGORY_SUBTYPES.POSITION_MOTIVATION ? (
                  <p style={{ position: 'relative', top: '24px' }}>좋습니다! 직무지원동기 함께 작성해보겠습니다!</p>
                ) : (null)}

                <div className={styles.footer_button} style={{ position: 'relative', width: '120px', top: '344px', margin: 'auto', background: isReady ? null : '#D9D9D9' }}>
                  <button className={styles.footer_button_text} onClick={isReady ? () => getData(selectedType, selectedSubType, 0) : null}>Start</button>
                </div>
              </>
            )}
          </>
        ) : selectedType === CATEGORY_TYPES.GROWTH_PROCESS ? (
          <>
            <p style={{ position: 'relative', top: '24px' }}>
              성장 과정에서 지원자님의 에피소드를 활용해 성격과 가치관을 보여주고, <br />
              지원하는 직무/회사에 따라 강조하고자하는 특성을 효과적으로 전달할 수 있습니다. <br />
              <br />
              먼저, 지원 기업의 인재상, 핵심가치 등이 있다면 적어주세요! <br />
              기업 홈페이지, 블로그, 현직자 인터뷰 등에서 이를 찾아볼 수 있습니다. <br />
              해당 사항이 없을 시 “없음”이라고 적어주세요!
            </p>
            <textarea
              className={styles.feedback_textarea}
              placeholder="내용을 입력하세요."
              rows={6}
              value={coreValues}
              onChange={(e) => handleCoreValuesChange(e)}
            />
            <div className={styles.footer_button} style={{ position: 'relative', width: '120px', top: '184px', margin: 'auto', background: isCoreValuePresent ? null : '#D9D9D9' }}>
              <button className={styles.footer_button_text} onClick={isCoreValuePresent ? () => getData(selectedType, selectedSubType, 0) : null}>Start</button>
            </div>
          </>
        ) : (
          <span>
          </span>
        )}
      </div>
    </div>
  )
}

const FeedbackStageOne = ({
  selectedType,
  CATEGORY_TYPES,
  selectedSubType,
  CATEGORY_SUBTYPES,
  question1,
  question2,
  question3,
  response,
  handleQuestionsChange,
  getData,
}) => {

  const isQuestionsPresent = (selectedSubType === '직무지원동기') ? question1.length > 0 && question2.length > 0 : (selectedType === '성장과정') ? question1.length > 0 && question2.length > 0 && question3.length > 0 : null;
  return (
    <div className={styles.feedback_container}>
      {selectedSubType === CATEGORY_SUBTYPES.INDUSTRY_MOTIVATION ? (
        <>
          <p className={styles.feedback_title}>피드백</p>
          <div className={styles.feedback_content_container} style={{ maxHeight: '75%' }}>
            <p style={{ fontSize: '20px', fontWeight: '700' }}>채용공고 분석 & 외부 검색</p>

    {response.keyword1 == null && (
              <div>
              <div style={{ display: 'flex', alignItems:'center', gap:'4px'}}>
                <img src={check_1} width= '16px' height='16px'></img>
              <p className={styles.feedback_steptext}>채용공고 내용에 기반한, 초안 작성 소재를 제공해드릴게요.</p>
              </div>
              <div style={{ display: 'flex', alignItems:'center', gap:'4px' }}>
                <img src={check_2} width= '16px' height='16px'></img>
              <p className={styles.feedback_steptext}>꼼꼼하게 피드백을 완성하고 있어요.</p>
              </div>
              <div style={{ display: 'flex', alignItems:'center', gap:'4px' }}>
                <img src={check_3} width= '16px' height='16px'></img>
              <p className={styles.feedback_steptext}>최대 3분 정도 소요될 수 있어요!</p>
              </div>
            </div>
  )}
            
            <div className={styles.feedback_content}>
              {response.keyword1 == null ? <div><LottieLoader width="100px" height="100px" /></div> : <p style={{ whiteSpace: 'pre-line' }}>{response.keyword1}</p>}
            </div>
            <div className={styles.feedback_content}>
              {response.keyword2 == null ? <div><LottieLoader width="100px" height="100px" /></div> : <p style={{ whiteSpace: 'pre-line' }}>{response.keyword2}</p>}
            </div>
            {/* <div className={styles.feedback_content_intro} style={{ color: '#2248B8' }}>
              <p style={{ whiteSpace: 'pre-line' }}>채용공고 분석, 외부 검색 소재를 참고해 지원 산업에 대한 관심과 관련 경험을 적어보세요.<br />산업 트렌드는 꼭 살펴보시는 것이 좋습니다!<br /><br />작성 후, 이어서 피드백을 받아보세요!</p>
            </div> */}
            <div className={styles.feedback_content_intro} style={{ color: '#2248B8' }}>
              <p style={{ whiteSpace: 'pre-line' }}>참고자료 제공이 완료되었습니다. 감사합니다.</p>
            </div>
          </div>
        </>
      ) : selectedSubType === CATEGORY_SUBTYPES.COMPANY_MOTIVATION ? (
        <>
          <p className={styles.feedback_title}>피드백</p>
          <div className={styles.feedback_content_container} style={{ maxHeight: '75%' }}>
            <p style={{ fontSize: '20px', fontWeight: '700' }}>채용공고 분석 & 외부 검색</p>
            <div className={styles.feedback_content}>
              {response.keyword1 == null ? <div><LottieLoader width="100px" height="100px" /></div> : <p style={{ whiteSpace: 'pre-line' }}>{response.keyword1}</p>}
            </div>
            <div className={styles.feedback_content}>
              {response.keyword2 == null ? <div><LottieLoader width="100px" height="100px" /></div> : <p style={{ whiteSpace: 'pre-line' }}>{response.keyword2}</p>}
            </div>
            <div className={styles.feedback_content_intro} style={{ color: '#2248B8' }}>
              <p style={{ whiteSpace: 'pre-line' }}>피드백 내용과 회사 홈페이지/블로그, 현직자 인터뷰, 회사 최신 이슈를 참고해<br />자기소개서 작성 후, 피드백을 받아보세요!</p>
            </div>
          </div>
        </>
      ) : selectedSubType === CATEGORY_SUBTYPES.POSITION_MOTIVATION ? (
        <>
          <p className={styles.feedback_title}>피드백</p>
          <div className={styles.feedback_content_container} style={{ maxHeight: '75%' }}>
            <p style={{ fontSize: '20px', fontWeight: '700' }}>채용공고 분석 & 외부 검색</p>
            <div className={styles.feedback_content}>
              {response.keyword1 == null ? <div><LottieLoader width="100px" height="100px" /></div> : <p style={{ whiteSpace: 'pre-line' }}>{response.keyword1}</p>}
            </div>
            <div className={styles.feedback_content}>
              {response.keyword2 == null ? <div><LottieLoader width="100px" height="100px" /></div> : <p style={{ whiteSpace: 'pre-line' }}>{response.keyword2}</p>}
            </div>
            <div className={styles.feedback_content}>
              {response.keyword3 == null ? <div><LottieLoader width="100px" height="100px" /></div> : <p style={{ whiteSpace: 'pre-line' }}>{response.keyword3}</p>}
            </div>
            <p style={{ position: 'relative', top: '16px', whiteSpace: 'pre-line' }}>{response.question1}</p>
            {response.question1 == null ? null : <textarea
              className={styles.feedback_textarea}
              placeholder="내용을 입력하세요."
              rows={6}
              value={question1}
              onChange={(e) => handleQuestionsChange(e, 1)}
            />}
            <p style={{ position: 'relative', top: '16px', whiteSpace: 'pre-line' }}>{response.question2}</p>
            {response.question2 == null ? null : <textarea
              className={styles.feedback_textarea}
              placeholder="내용을 입력하세요."
              rows={6}
              value={question2}
              onChange={(e) => handleQuestionsChange(e, 2)}
            />}
            <div className={styles.feedback_content_intro} style={{ color: '#2248B8' }}>
              <p style={{ whiteSpace: 'pre-line' }}>내용 작성 후, 초안 생성 버튼을 클릭해주세요!</p>
            </div>
            <div className={styles.footer_button} style={{ position: 'relative', width: '150px', top: '4px', left: '400px', background: isQuestionsPresent ? null : '#D9D9D9' }}>
              <button className={styles.footer_button_text} onClick={isQuestionsPresent ? () => getData(selectedType, selectedSubType, 1) : null}>초안 생성</button>
            </div>
          </div>
        </>
      ) : selectedType === CATEGORY_TYPES.GROWTH_PROCESS ? (
        <>
          <p className={styles.feedback_title}>피드백</p>
          <div className={styles.feedback_content_container} style={{ maxHeight: '75%' }}>
            <p style={{ fontSize: '20px', fontWeight: '700' }}>채용공고 기반 소재 추천</p>
            <div className={styles.feedback_content}>
              {response.recommendation == null ? <div><LottieLoader width="100px" height="100px" /></div> : <p style={{ whiteSpace: 'pre-line' }}>{response.recommendation}</p>}
            </div>
            <p style={{ position: 'relative', top: '16px', whiteSpace: 'pre-line' }}>{response.question1}</p>
            {response.question1 == null ? null : <textarea
              className={styles.feedback_textarea}
              placeholder="내용을 입력하세요."
              rows={6}
              value={question1}
              onChange={(e) => handleQuestionsChange(e, 1)}
            />}
            <p style={{ position: 'relative', top: '16px', whiteSpace: 'pre-line' }}>{response.question2}</p>
            {response.question2 == null ? null : <textarea
              className={styles.feedback_textarea}
              placeholder="내용을 입력하세요."
              rows={6}
              value={question2}
              onChange={(e) => handleQuestionsChange(e, 2)}
            />}
            <p style={{ position: 'relative', top: '16px', whiteSpace: 'pre-line' }}>{response.question3}</p>
            {response.question3 == null ? null : <textarea
              className={styles.feedback_textarea}
              placeholder="내용을 입력하세요."
              rows={6}
              value={question3}
              onChange={(e) => handleQuestionsChange(e, 3)}
            />}
            <div className={styles.feedback_content_intro} style={{ color: '#2248B8' }}>
              <p style={{ whiteSpace: 'pre-line' }}>내용 작성 후, 초안 생성 버튼을 클릭해주세요!</p>
            </div>
            <div className={styles.footer_button} style={{ position: 'relative', width: '150px', top: '4px', left: '400px', background: isQuestionsPresent ? null : '#D9D9D9' }}>
              <button className={styles.footer_button_text} onClick={isQuestionsPresent ? () => getData(selectedType, selectedSubType, 1) : null}>초안 생성</button>
            </div>
          </div>
        </>
      ) : response === '' && selectedType != "" ? (
        <div>
          <p>loading</p>
        </div>
      ) : (
        <div>
          <p>shit</p>
        </div>
      )}
    </div>
  )
}

const FeedbackStageTwo = ({
  selectedType,
  CATEGORY_TYPES,
  selectedSubType,
  CATEGORY_SUBTYPES,
  response,
}) => {

  return (
    <div className={styles.feedback_container}>
      {selectedSubType === CATEGORY_SUBTYPES.INDUSTRY_MOTIVATION ? (
        <>
          <p className={styles.feedback_title}>피드백</p>
          <div className={styles.feedback_content_container} style={{ maxHeight: '75%' }}>
            <div className={styles.feedback_content}>
              {response.checklist1 == null ? <div><LottieLoader width="100px" height="100px" /></div> : <p style={{ whiteSpace: 'pre-line' }}>{response.checklist1}</p>}
            </div>
            <div className={styles.feedback_content_intro} style={{ color: '#2248B8' }}>
              <p style={{ whiteSpace: 'pre-line' }}>피드백 활용해 자기소개서 수정 후, 피드백 받기를 클릭해주세요!</p>
            </div>
          </div>
        </>
      ) : selectedSubType === CATEGORY_SUBTYPES.COMPANY_MOTIVATION ? (
        <>
          <p className={styles.feedback_title}>피드백</p>
          <div className={styles.feedback_content_container} style={{ maxHeight: '75%' }}>
            <div className={styles.feedback_content}>
              {response.checklist1 == null ? <div><LottieLoader width="100px" height="100px" /></div> : <p style={{ whiteSpace: 'pre-line' }}>{response.checklist1}</p>}
            </div>
            <div className={styles.feedback_content_intro} style={{ color: '#2248B8' }}>
              <p>피드백 활용해 자기소개서 수정 후, 피드백 받기를 클릭해주세요!</p>
            </div>
          </div>
        </>
      ) : selectedSubType === CATEGORY_SUBTYPES.POSITION_MOTIVATION ? (
        <>
          <p className={styles.feedback_title}>피드백</p>
          {response.draft != null ? null : <div style={{ marginTop: '200px' }}><LottieLoader width="200px" height="200px" /></div>}
        </>
      ) : selectedType === CATEGORY_TYPES.GROWTH_PROCESS ? (
        <>
          <p className={styles.feedback_title}>피드백</p>
          {response.draft1 != null ? null : <div style={{ marginTop: '200px' }}><LottieLoader width="200px" height="200px" /></div>}


        </>
      ) : (
        <div>

        </div>
      )}
    </div>
  )
}

const FeedbackStageThree = ({
  selectedType,
  CATEGORY_TYPES,
  selectedSubType,
  CATEGORY_SUBTYPES,
  response,
}) => {

  return (
    <div className={styles.feedback_container}>
      {selectedSubType === CATEGORY_SUBTYPES.INDUSTRY_MOTIVATION ? (
        <>
          <p className={styles.feedback_title}>피드백</p>
          <div className={styles.feedback_content_container} style={{ maxHeight: '75%' }}>
            <div className={styles.feedback_content}>
              {response.envision1 == null ? <div><LottieLoader width="100px" height="100px" /></div> : <p style={{ whiteSpace: 'pre-line' }}>{response.envision1}</p>}
            </div>
            <div className={styles.feedback_content}>
              {response.envision2 == null ? <div><LottieLoader width="100px" height="100px" /></div> : <p style={{ whiteSpace: 'pre-line' }}>{response.envision2}</p>}
            </div>
            <div className={styles.feedback_content_intro} style={{ color: '#2248B8' }}>
              <p style={{ whiteSpace: 'pre-line' }}>피드백 활용해 자기소개서 수정 후, 피드백 받기를 클릭해주세요!</p>
            </div>
          </div>
        </>
      ) : selectedSubType === CATEGORY_SUBTYPES.COMPANY_MOTIVATION ? (
        <>
          <p className={styles.feedback_title}>피드백</p>
          <div className={styles.feedback_content_container} style={{ maxHeight: '75%' }}>
            <div className={styles.feedback_content}>
              {response.envision1 == null ? <div><LottieLoader width="100px" height="100px" /></div> : <p style={{ whiteSpace: 'pre-line' }}>{response.envision1}</p>}
            </div>
            <div className={styles.feedback_content}>
              {response.envision2 == null ? <div><LottieLoader width="100px" height="100px" /></div> : <p style={{ whiteSpace: 'pre-line' }}>{response.envision2}</p>}
            </div>
            <div className={styles.feedback_content_intro} style={{ color: '#2248B8' }}>
              <p>피드백 활용해 자기소개서 수정 후, 피드백 받기를 클릭해주세요!</p>
            </div>
          </div>
        </>
      ) : selectedSubType === CATEGORY_SUBTYPES.POSITION_MOTIVATION ? (
        <>
          <p className={styles.feedback_title}>피드백</p>
          <div className={styles.feedback_content_container} style={{ maxHeight: '75%' }}>
            <div className={styles.feedback_content}>
              {response.improvement1 == null ? <div><LottieLoader width="100px" height="100px" /></div> : <p style={{ whiteSpace: 'pre-line' }}>{response.improvement1}</p>}
            </div>
            <div className={styles.feedback_content}>
              {response.improvement2 == null ? <div><LottieLoader width="100px" height="100px" /></div> : <p style={{ whiteSpace: 'pre-line' }}>{response.improvement2}</p>}
            </div>
            <div className={styles.feedback_content_intro} style={{ color: '#2248B8' }}>
              <p>피드백 활용해 자기소개서 수정 후, 피드백 받기를 클릭해주세요!</p>
            </div>
          </div>
        </>
      ) : selectedType === CATEGORY_TYPES.GROWTH_PROCESS ? (
        <>
          <p className={styles.feedback_title}>피드백</p>
          <div className={styles.feedback_content_container} style={{ maxHeight: '75%' }}>
            <div className={styles.feedback_content}>
              {response.envision == null ? <div><LottieLoader width="100px" height="100px" /></div> : <p style={{ whiteSpace: 'pre-line' }}>{response.envision}</p>}
            </div>
            <div className={styles.feedback_content_intro} style={{ color: '#2248B8' }}>
              <p>피드백 활용해 자기소개서 수정 후, 피드백 받기를 클릭해주세요!</p>
            </div>
          </div>
        </>
      ) : (
        <div>
        </div>
      )}
    </div>
  )
}

const FeedbackStageFour = ({
  selectedType,
  CATEGORY_TYPES,
  selectedSubType,
  CATEGORY_SUBTYPES,
  response,
}) => {

  return (
    <div className={styles.feedback_container}>
      {selectedSubType === CATEGORY_SUBTYPES.INDUSTRY_MOTIVATION ? (
        <>
          <p className={styles.feedback_title}>피드백</p>
          <div className={styles.feedback_content_container} style={{ maxHeight: '75%' }}>
            <div className={styles.feedback_content}>
              {response.quants == null ? <div><LottieLoader width="100px" height="100px" /></div> : <p style={{ whiteSpace: 'pre-line' }}>{response.quants}</p>}
            </div>
            <div className={styles.feedback_content}>
              {response.titlerec1 == null ? <div><LottieLoader width="100px" height="100px" /></div> : <p style={{ whiteSpace: 'pre-line' }}>{response.titlerec1}</p>}
            </div>
            <div className={styles.feedback_content_intro} style={{ color: '#2248B8' }}>
              <p style={{ whiteSpace: 'pre-line' }}>피드백이 완료되었습니다. 감사합니다.</p>
            </div>
          </div>
        </>
      ) : selectedSubType === CATEGORY_SUBTYPES.COMPANY_MOTIVATION ? (
        <>
          <p className={styles.feedback_title}>피드백</p>
          <div className={styles.feedback_content_container} style={{ maxHeight: '75%' }}>
            <div className={styles.feedback_content}>
              {response.quants == null ? <div><LottieLoader width="100px" height="100px" /></div> : <p style={{ whiteSpace: 'pre-line' }}>{response.quants}</p>}
            </div>
            <div className={styles.feedback_content}>
              {response.titlerec1 == null ? <div><LottieLoader width="100px" height="100px" /></div> : <p style={{ whiteSpace: 'pre-line' }}>{response.titlerec1}</p>}
            </div>
            <div className={styles.feedback_content_intro} style={{ color: '#2248B8' }}>
              <p>피드백이 완료되었습니다. 감사합니다.</p>
            </div>
          </div>
        </>
      ) : selectedSubType === CATEGORY_SUBTYPES.POSITION_MOTIVATION ? (
        <>
          <p className={styles.feedback_title}>피드백</p>
          <div className={styles.feedback_content_container} style={{ maxHeight: '75%' }}>
            <div className={styles.feedback_content}>
              {response.quants == null ? <div><LottieLoader width="100px" height="100px" /></div> : <p style={{ whiteSpace: 'pre-line' }}>{response.quants}</p>}
            </div>
            <div className={styles.feedback_content}>
              {response.titlerec1 == null ? <div><LottieLoader width="100px" height="100px" /></div> : <p style={{ whiteSpace: 'pre-line' }}>{response.titlerec1}</p>}
            </div>
            <div className={styles.feedback_content_intro} style={{ color: '#2248B8' }}>
              <p>피드백이 완료되었습니다. 감사합니다.</p>
            </div>
          </div>
        </>
      ) : selectedType === CATEGORY_TYPES.GROWTH_PROCESS ? (
        <>
          <p className={styles.feedback_title}>피드백</p>
          <div className={styles.feedback_content_container} style={{ maxHeight: '75%' }}>
            <div className={styles.feedback_content}>
              {response.quants == null ? <div><LottieLoader width="100px" height="100px" /></div> : <p style={{ whiteSpace: 'pre-line' }}>{response.quants}</p>}
            </div>
            <div className={styles.feedback_content}>
              {response.titlerec1 == null ? <div><LottieLoader width="100px" height="100px" /></div> : <p style={{ whiteSpace: 'pre-line' }}>{response.titlerec1}</p>}
            </div>
            <div className={styles.feedback_content_intro} style={{ color: '#2248B8' }}>
              <p>피드백 활용해 자기소개서 수정 후, 피드백 받기를 클릭해주세요!</p>
            </div>
          </div>
        </>
      ) : (
        <div>
        </div>
      )}
    </div>
  )
}

const FeedbackStageFive = ({
  selectedType,
  CATEGORY_TYPES,
  selectedSubType,
  CATEGORY_SUBTYPES,
  response,
}) => {

  return (
    <div className={styles.feedback_container}>
      {selectedSubType === CATEGORY_SUBTYPES.INDUSTRY_MOTIVATION ? (
        /*
        <>
          <p className={styles.feedback_title}>피드백</p>
          <div className={styles.feedback_content_container} style={{ maxHeight: '75%' }}>
            <div className={styles.feedback_content}>
              <p style={{whiteSpace: 'pre-line'}}>{response.wordcount1 + " ("+response.wordcount1+")자"}</p>
            </div>
            <div className={styles.feedback_content_intro} style={{ color: '#2248B8' }}>
              <p style={{whiteSpace: 'pre-line'}}>피드백이 완료되었습니다. 감사합니다.</p>
            </div>
          </div>
        </>
        */
        <>
        </>
      ) : selectedSubType === CATEGORY_SUBTYPES.COMPANY_MOTIVATION ? (
        /*
        <>
          <p className={styles.feedback_title}>피드백</p>
          <div className={styles.feedback_content_container} style={{ maxHeight: '75%' }}>
            <div className={styles.feedback_content}>
              <p style={{whiteSpace: 'pre-line'}}>{response.wordcount1 + " ("+response.wordcount1+")자"}</p>
            </div>
            <div className={styles.feedback_content_intro} style={{ color: '#2248B8' }}>
              <p>피드백이 완료되었습니다. 감사합니다.</p>
            </div>
          </div>
        */
        <>
        </>
      ) : selectedSubType === CATEGORY_SUBTYPES.POSITION_MOTIVATION ? (
        /*
      <>
        <p className={styles.feedback_title}>피드백</p>
        <div className={styles.feedback_content_container} style={{ maxHeight: '75%' }}>
          <div className={styles.feedback_content}>
            <p style={{whiteSpace: 'pre-line'}}>{response.wordcount1 + " ("+response.wordcount1+")자"}</p>
          </div>
          <div className={styles.feedback_content_intro} style={{ color: '#2248B8' }}>
            <p>피드백이 완료되었습니다. 감사합니다.</p>
          </div>
        </div>
      */
        <>
        </>
      ) : selectedType === CATEGORY_TYPES.GROWTH_PROCESS ? (
        <>
          <p className={styles.feedback_title}>피드백</p>
          <div className={styles.feedback_content_container} style={{ maxHeight: '75%' }}>
            <div className={styles.feedback_content}>
              {response.cheer == null ? <div><LottieLoader width="100px" height="100px" /></div> : <p style={{ whiteSpace: 'pre-line' }}>{response.cheer}</p>}
            </div>
            <div className={styles.feedback_content_intro} style={{ color: '#2248B8' }}>
              <p>피드백이 완료되었습니다. 감사합니다.</p>
            </div>
          </div>
        </>
      ) : (
        <div>
        </div>
      )}
    </div>
  );
}

const Feedback = ({ currentStage, ...props }) => {
  switch (currentStage) {
    case 0:
      return <FeedbackStageZero {...props} />;
    case 1:
      return <FeedbackStageOne {...props} />;
    case 2:
      return <FeedbackStageTwo {...props} />;
    case 3:
      return <FeedbackStageThree {...props} />;
    case 4:
      return <FeedbackStageFour {...props} />;
    case 5:
      return <FeedbackStageFive {...props} />;
    default:
      return <div className={styles.feedback_container}></div>;
  }
}

export default Feedback;
