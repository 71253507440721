import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, signInWithPopup, GoogleAuthProvider, createUserWithEmailAndPassword } from "firebase/auth";

/*
환경 변수 설정 예정
*/

const firebaseConfig = {
  apiKey: "AIzaSyDtDHAvc_XSKqlXaGS0WAVKMCDvFwsD2mA",
  authDomain: "jobgo-e6fb3.firebaseapp.com",
  projectId: "jobgo-e6fb3",
  storageBucket: "jobgo-e6fb3.appspot.com",
  messagingSenderId: "437584737210",
  appId: "1:437584737210:web:a0bc2a0db18fb66c762383",
  measurementId: "G-2J0MNRDX1F"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const provider = new GoogleAuthProvider();
provider.setCustomParameters({   
  prompt : "select_account "
});

export const auth = getAuth();
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);
export const createUser = (email, pwd) => createUserWithEmailAndPassword(auth, email, pwd);
export { db };
