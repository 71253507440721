import axios from 'axios';

const industryKeyword = async (industry, company, position, hireNotice) => {
  try {
    const response = await axios.post('https://industrykeyword-363xdfs7kq-uc.a.run.app', {
      data: {
        industry: industry,
        company: company,
        position: position,
        hireNotice: hireNotice
      }
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

const industryChecklist = async (industry, answer) => {
  try {
    const response = await axios.post('https://industrychecklist-363xdfs7kq-uc.a.run.app', {
      data: {
        industry: industry,
        answer: answer
      }
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

const industryEnvision = async (industry, answer) => {
  try {
    const response = await axios.post('https://industryenvision-363xdfs7kq-uc.a.run.app', {
      data: {
        industry: industry,
        answer: answer
      }
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

const industryQuantTitle = async (industry, answer) => {
  try {
    const response = await axios.post('https://industryquanttitle-363xdfs7kq-uc.a.run.app', {
      data: {
        industry: industry,
        answer: answer
      }
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

const industryWordcount = async (industry, answer, wordcount) => {
  try {
    const response = await axios.post('https://industrywordcount-363xdfs7kq-uc.a.run.app', {
      data: {
        industry: industry,
        answer: answer,
        wordcount: wordcount
      }
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

const companyKeyword = async (company, position, hireNotice) => {
  try {
    const response = await axios.post('https://companykeyword-363xdfs7kq-uc.a.run.app', {
      data: {
        company: company,
        position: position,
        hireNotice: hireNotice
      }
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

const companyChecklist = async (company, position, answer) => {
  try {
    const response = await axios.post('https://companychecklist-363xdfs7kq-uc.a.run.app', {
      data: {
        company: company,
        position: position,
        answer: answer
      }
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

const companyEnvision = async (company, position, answer) => {
  try {
    const response = await axios.post('https://companyenvision-363xdfs7kq-uc.a.run.app', {
      data: {
        company: company,
        position: position,
        answer: answer
      }
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

const companyQuantTitle = async (answer) => {
  try {
    const response = await axios.post('https://companyquanttitle-363xdfs7kq-uc.a.run.app', {
      data: {
        answer: answer
      }
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

const companyWordcount = async (answer, wordcount) => {
  try {
    const response = await axios.post('https://companywordcount-363xdfs7kq-uc.a.run.app', {
      data: {
        answer: answer,
        wordcount: wordcount
      }
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

const positionKeyword = async (company, position, hireNotice) => {
  try {
    const response = await axios.post('https://positionkeyword-363xdfs7kq-uc.a.run.app', {
      data: {
        company: company,
        position: position,
        hireNotice: hireNotice
      }
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

const positionDraft = async (question1, question2) => {
  try {
    const response = await axios.post('https://positiondraft-363xdfs7kq-uc.a.run.app', {
      data: {
        question1: question1,
        question2: question2
      }
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

const positionImprovement = async (industry, position, answer) => {
  try {
    const response = await axios.post('https://positionimprovement-363xdfs7kq-uc.a.run.app', {
      data: {
        industry: industry,
        position: position,
        answer: answer
      }
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

const positionTitle = async (answer) => {
  try {
    const response = await axios.post('https://positiontitle-363xdfs7kq-uc.a.run.app', {
      data: {
        answer: answer
      }
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

const positionWordcount = async (answer, wordcount) => {
  try {
    const response = await axios.post('https://positionwordcount-363xdfs7kq-uc.a.run.app', {
      data: {
        answer: answer,
        wordcount: wordcount,
      }
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

const growthRecommendation = async (company, position, hireNotice, coreValues) => {
  try {
    const response = await axios.post('https://growthrecommendation-363xdfs7kq-uc.a.run.app', {
      data: {
        company: company,
        position: position,
        hireNotice: hireNotice,
        coreValues: coreValues
      }
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

const growthDraft = async (question1, question2, question3) => {
  try {
    const response = await axios.post('https://growthdraft-363xdfs7kq-uc.a.run.app', {
      data: {
        question1: question1,
        question2: question2,
        question3: question3
      }
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

const growthEnvision = async (answer) => {
  try {
    const response = await axios.post('https://growthenvision-363xdfs7kq-uc.a.run.app', {
      data: {
        answer: answer
      }
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

const growthQuantTitle = async (answer) => {
  try {
    const response = await axios.post('https://growthquanttitle-363xdfs7kq-uc.a.run.app', {
      data: {
        answer: answer
      }
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

const growthCheer = async (answer) => {
  try {
    const response = await axios.post('https://growthcheer-363xdfs7kq-uc.a.run.app', {
      data: {
        answer: answer
      }
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export {
  industryKeyword,
  industryChecklist,
  industryEnvision,
  industryQuantTitle,
  industryWordcount,
  companyKeyword,
  companyChecklist,
  companyEnvision,
  companyQuantTitle,
  companyWordcount,
  positionKeyword,
  positionDraft,
  positionImprovement,
  positionTitle,
  positionWordcount,
  growthRecommendation,
  growthDraft,
  growthEnvision,
  growthQuantTitle,
  growthCheer
};
