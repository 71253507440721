import styles from './SideBar.module.css';
import logo from './images/logo.svg';
import chat from './images/chat.svg';
import chat_icon from './images/chat_icon.svg'
import open from './images/open.svg';
import open_icon from './images/open_icon.svg';
import signout from './images/signout.svg';
import signout_icon from './images/signout_icon.svg';
import { ReactComponent as Recruitment } from './images/recruitment.svg';
import recruitment_icon from './images/recruitment_icon.svg';
import { ReactComponent as Script } from './images/script.svg';
import script_icon from './images/script_icon.svg';

const SideBar = ({
  isHovered,
  setIsHovered,
  movePage,
  setModalOpen,
  signOut
}) => {
  return (
    <div
      className={`${styles.side_container} ${isHovered ? styles.hovered : ''}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      >
      {isHovered ? (
        <>
          <img className={styles.logo} src={logo} width={34} height={39} onClick={() => movePage('/')}/>
          <div className={styles.recruitment_container}>
            <button>
              <Recruitment className={styles.recruitment_hovered} onClick={() => setModalOpen(true)} width={122.4} height={19.2} />
            </button>
          </div>
          <div className={styles.script_container}>
            <button>
              <Script className={styles.script_hovered} width={152} height={19.2} />
            </button>
          </div>
          <div className={styles.chat_container}>
            <button onClick={() => window.open('http://pf.kakao.com/_PfmBG/chat', '_blank')}>
              <img className={styles.chat_hovered} src={chat} width={103} height={24} />
            </button>
          </div>
          <div className={styles.open_container}>
            <button onClick={() => window.open('https://open.kakao.com/o/soJfnGtf', '_blank')}>
              <img className={styles.open_hovered} src={open} width={107} height={24} />
            </button>
          </div>
          <div className={styles.signout_container}>
            <button onClick={signOut}>
              <img className={styles.signout_hovered} src={signout} width={93} height={24} />
            </button>
          </div>
        </>
      ) : (
        <>
          <img className={styles.logo} src={logo} width={34} height={39} />
          <div className={styles.recruitment_container}>
            <img className={styles.recruitment} src={recruitment_icon} width={20} height={20} />
          </div>
          <div className={styles.script_container}>
            <img className={styles.script} src={script_icon} width={20} height={20} />
          </div>
          <div className={styles.chat_container}>
            <button onClick={() => window.open('http://pf.kakao.com/_PfmBG/chat', '_blank')}>
              <img className={styles.chat} src={chat_icon} width={16} height={15} />
            </button>
          </div>
          <div className={styles.open_container}>
            <button onClick={() => window.open('https://open.kakao.com/o/soJfnGtf', '_blank')}>
              <img className={styles.open} src={open_icon} width={13} height={13} />
            </button>
          </div>
          <div className={styles.signout_container}>
            <button>
              <img className={styles.signout} src={signout_icon} width={16} height={14} />
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default SideBar;
