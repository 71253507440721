import styles from './Modal.module.css';

const Modal = ({ 
  industry, 
  setIndustry, 
  company, 
  setCompany, 
  position, 
  setPosition, 
  hireNotice, 
  setHireNotice, 
  setCardInfo 
}) => {
  const areAllModalValuesPresent = industry.length > 0 && company.length > 0 && position.length > 0 && hireNotice.length > 0;
  
  return (
    <div className={styles.modal_backdrop}>
      <div className={styles.modal_content}>
        <p className={styles.modal_title}>채용공고 입력</p>
        <p className={styles.modal_header}>산업</p>
        <input className={styles.modal_input} type="text" placeholder="산업을 입력해 주세요. (예:인터넷 전문 은행업)" value={industry} onChange={(e) => setIndustry(e.target.value)}></input>
        <p className={styles.modal_header}>회사</p>
        <input className={styles.modal_input} type="text" placeholder="회사를 입력해 주세요. (예:토스 뱅크)" value={company} onChange={(e) => setCompany(e.target.value)}></input>
        <p className={styles.modal_header}>직무</p>
        <input className={styles.modal_input} type="text" placeholder="직무를 입력해 주세요. (예:Content Marketing Assistant)" value={position} onChange={(e) => setPosition(e.target.value)}></input>
        <p className={styles.modal_header}>공고 내용</p>
        <textarea
          className={styles.modal_textarea}
          placeholder="공고 내용을 입력해 주세요. (예:마케팅/광고/콘텐츠 전반에 관심이 많고, 리서치를 디테일하게 해주시는 분이 필요해요. 기본적인 글쓰기 역량과 콘텐츠 제작 관련 일정 관리 경험을 가진 분이 필요해요. 콘텐츠 제작을 위한 자료조사부터 읽기 편한 콘텐츠 작성이 가능한 분이면 좋겠어요. 경제/금융 지식이 있는 분, 평소 다양한 형태의 콘텐츠를 소비하는 분이면 좋겠어요.)"
          rows={6}
          value={hireNotice}
          onChange={(e) => setHireNotice(e.target.value)}
        />
        <br />
        <span className={styles.modal_caution} style={{ color: '#FF0000' }}>
          *회사, 직무, 공고 내용은 필수 입력 사항입니다.
        </span>
        <br />
        <span className={styles.modal_caution} style={{ color: '#2248B8' }}>
          *급여, 계약 형태, 기간, 근무 시간 등
          <br />
          직무 및 회사 내용과 거리가 먼 내용은 기재를 삼가주세요!
        </span>
        <div className={styles.modal_button} style={{ background: areAllModalValuesPresent ? null : '#D9D9D9' }}>
          <button className={styles.modal_button_text} onClick={areAllModalValuesPresent ? () => setCardInfo() : null}>다음으로</button>
        </div>
      </div>
    </div>
  );
}

export default Modal;
