import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LoginPage from './components/LoginPage/LoginPage.js'
import HomePage from './components/HomePage/HomePage.js';
import RecruitmentPage from './components/RecruitmentPage/RecruitmentPage.js';
import styles from './App.module.css';

function App() {
  return (
    <div className={styles.App}>
      <BrowserRouter>
        <Routes>
          <Route path={"/login"} element={<LoginPage />} />
          <Route path={"/"} element={<HomePage />} />
          <Route path={"/recruitment"} element={<RecruitmentPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
