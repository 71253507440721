import styles from './RecruitmentPage.module.css';
import Modal from './Modal.js';
import SideBar from './SideBar.js';
import Menu from './Menu.js';
import Feedback from './Feedback.js';

import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { db } from '../../hooks/Firebase.js';
import { doc, getDoc, updateDoc, addDoc, collection, Timestamp } from "firebase/firestore";

import { 
  industryKeyword,
  industryChecklist,
  industryEnvision,
  industryQuantTitle,
  industryWordcount,
  companyKeyword,
  companyChecklist,
  companyEnvision,
  companyQuantTitle,
  companyWordcount,
  positionKeyword,
  positionDraft,
  positionImprovement,
  positionTitle,
  positionWordcount,
  growthRecommendation,
  growthDraft,
  growthEnvision,
  growthQuantTitle,
  growthCheer 
} from "../../hooks/api.js";

// 로직 개선 필요
// 에러 핸들링 개선 필요
// 상태 관리 최적화 필요
// BEM에 따라 CSS class Name 수정 필요

const RecruitmentPage = () => {
  const [isModalOpen, setModalOpen] = useState(true);
  const [industry, setIndustry] = useState('');
  const [company, setCompany] = useState('');
  const [position, setPosition] = useState('');
  const [hireNotice, setHireNotice] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const [textAreaContent, setTextAreaContent] = useState('');
  const [currentCharCount, setCurrentCharCount] = useState(0);
  const [charLimit, setCharLimit] = useState(1000);

  const [isTypeSelected, setIsTypeSelected] = useState(false);
  const [isSubTypeSelected,setIsSubTypeSelected] =useState(false);
  const [selectedType, setSelectedType] = useState('문항종류');
  const [selectedSubType, setSelectedSubType] = useState('문항종류');
  const [isReady, setIsReady] = useState(false);
  const [currentStage, setCurrentStage] = useState(0);
  const [response, setResponse] = useState({});
  const [coreValues, setCoreValues] = useState('');
  const [question1, setQuestion1] = useState('');
  const [question2, setQuestion2] = useState('');
  const [question3, setQuestion3] = useState('');

  const CATEGORY_TYPES = {
    APPLICATION_MOTIVATION: '연구개발직무',
    // GROWTH_PROCESS: '성장과정',
    // EXPERIENCE: '경험/직무역량/강점',
    // PERSONALITY: '성격상 장단점',
    // FUTURE_GOALS: '입사 후 포부',
    // OTHERS: '기타',
  };
  
  const CATEGORY_SUBTYPES = {
    INDUSTRY_MOTIVATION: '직무인지',
    // COMPANY_MOTIVATION: '회사지원동기',
    // POSITION_MOTIVATION: '직무지원동기',
  };

  const movePage = useNavigate();

  const isInputValuePresent = currentCharCount > 0;


  useEffect(() => {
    getCardInfo();
  }, []);

  useEffect(() => {
    if (selectedType === '성장과정' && response.draft1) {
      setTextAreaContent(response.draft1);
    } else if (selectedSubType === '직무지원동기' && response.draft) {
      setTextAreaContent(response.draft);
    }
  }, [selectedType, selectedSubType, response.draft,response.draft1]);

  const getCardInfo = () => {
    const cardInfo = sessionStorage.getItem('cardInfo');
    const card = JSON.parse(cardInfo);
    if (card !== null) {
      setIndustry(card.industry);
      setCompany(card.company);
      setPosition(card.position);
      setHireNotice(card.hireNotice);
      setModalOpen(false);
    }
  };

  const setCardInfo = async () => {
    const uid = sessionStorage.getItem('userID');
    if (uid === null) {
      console.log("uid error");
      return;
    }

    const cardData = {
      author: uid,
      industry: industry,
      company: company,
      position: position,
      pinned: false,
      question: [question1, question2, question3],
      hireNotice: hireNotice,
      editedDate: Timestamp.now(),
    };

    const cardInfo = sessionStorage.getItem('cardInfo');
    if (cardInfo !== null) {
      const card = JSON.parse(cardInfo);
      const cardRef = doc(db, 'cards', card.id);
      await updateDoc(cardRef, cardData);
    } else {
      await addDoc(collection(db, 'cards'), cardData);
    }
    
    setModalOpen(false);
  };

  const signOut = () => {
    sessionStorage.clear();
    movePage('/login');
  };

  const handleTextAreaChange = (event) => {
    const value = event.target.value;
    const charCount = value.length;
    setTextAreaContent(value);
    setCurrentCharCount(charCount);
  };

  const handleCoreValuesChange = (event) => {
    const value = event.target.value;
    setCoreValues(value);
  };

  const handleQuestionsChange = (event, index) => {
    const value = event.target.value;
    if (index === 1) {
      setQuestion1(value);
    } else if (index === 2) {
      setQuestion2(value);
    } else if (index === 3) {
      setQuestion3(value);
    } else {
      console.log("error");
    }
  };

  const handleCharLimit = (event) => {
    const inputValue = event.target.value;
    if (/^\d{0,4}$/.test(inputValue)) {
      setCharLimit(inputValue);
    } else {
      console.log("4자리 이하 정수를 입력해주세요.");
    }
  };

  const toggleCategoryType = () => {
    setIsTypeSelected(!isTypeSelected);
  };

  const toggleCategorySubType = () => {
    setIsSubTypeSelected(!isSubTypeSelected);
  };

  const handleCategoryType = (category) => {
    setSelectedType(category);
    if (category === '지원동기') {
      setIsReady(false);
      setSelectedSubType('문항종류');
    } else {
      setIsReady(true);
    }
    toggleCategoryType();
  };

  const handleCategorySubType = (category) => {
    setSelectedSubType(category);
    setIsReady(true);
    toggleCategorySubType();
  };

  const getData = async (type, subType, stage) => {
    try {
      const cardInfo = sessionStorage.getItem('cardInfo');
      const card = JSON.parse(cardInfo);
      const nextStage = stage + 1;
      setCurrentStage(nextStage);
  
      switch (type) {
        case CATEGORY_TYPES.APPLICATION_MOTIVATION:
          await handleApplicationMotivation(subType, nextStage, card, textAreaContent, question1, question2);
          await setQuestionData();
          break;
        case CATEGORY_TYPES.GROWTH_PROCESS:
          await handleGrowthProcess(nextStage, card, coreValues, textAreaContent, question1, question2, question3);
          await setQuestionData();
          break;
        default:
          console.error("Invalid type");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const setQuestionData = async () => {
    const uid = sessionStorage.getItem('userID');
    if (uid === null) {
      console.log("uid error");
      return;
    }

    const questionData = {
      author: uid,
      type: CATEGORY_TYPES.indexOf(selectedType),
      subType: CATEGORY_SUBTYPES.indexOf(selectedSubType),
      response: textAreaContent,
      order: 1,
      stage: currentStage,
      feedBacks: response,
    };

    await addDoc(collection(db, 'questions'), questionData);
  };

  const handleApplicationMotivation = async (subType, nextStage, card, textAreaContent, question1, question2) => {
    try {
      switch (subType) {
        case CATEGORY_SUBTYPES.INDUSTRY_MOTIVATION:
          const industryResult = await handleIndustry(nextStage, card.industry, card.company, card.position, card.hireNotice, textAreaContent, charLimit);
          setResponse(industryResult.result);
          break;
        case CATEGORY_SUBTYPES.COMPANY_MOTIVATION:
          const companyResult = await handleCompany(nextStage, card.company, card.position, card.hireNotice, textAreaContent, charLimit);
          setResponse(companyResult.result);
          break;
        case CATEGORY_SUBTYPES.POSITION_MOTIVATION:
          const positionResult = await handlePosition(nextStage, card.industry, card.company, card.position, card.hireNotice, textAreaContent, question1, question2, charLimit);
          setResponse(positionResult.result);
          break;
        default:
          console.error("Invalid subtype");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  
  const handleGrowthProcess = async (nextStage, card, coreValues, textAreaContent, question1, question2, question3) => {
    try {
      const growthResult = await handleGrowth(nextStage, card.company, card.position, card.hireNotice, coreValues, textAreaContent, question1, question2, question3, charLimit);
      setResponse(growthResult.result);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleIndustry = async (stage, industry, company, position, hireNotice, textAreaContent, charLimit) => {
    switch (stage) {
      case 1:
        return await industryKeyword(industry, company, position, hireNotice);
      // case 2:
      //   return await industryChecklist(industry, textAreaContent);
      // case 3:
      //   return await industryEnvision(industry, textAreaContent);
      // case 4:
      //   return await industryQuantTitle(industry, textAreaContent);
      // case 5:
      //   return await industryWordcount(industry, textAreaContent, charLimit);
      default:
        console.error("Invalid stage");
        return null;
    }
  };

  const handleCompany = async (stage, company, position, hireNotice, textAreaContent, charLimit) => {
    switch (stage) {
      case 1:
        return await companyKeyword(company, position, hireNotice);
      case 2:
        return await companyChecklist(company, position, textAreaContent);
      case 3:
        return await companyEnvision(company, position, textAreaContent);
      case 4:
        return await companyQuantTitle(textAreaContent);
      case 5:
        return await companyWordcount(textAreaContent, charLimit);
      default:
        console.error("Invalid stage");
        return null;
    }
  };

  const handlePosition = async (stage, industry, company, position, hireNotice, textAreaContent, question1, question2, charLimit) => {
    switch (stage) {
      case 1:
        return await positionKeyword(company, position, hireNotice);
      case 2:
        return await positionDraft(question1, question2);
      case 3:
        return await positionImprovement(industry, position, textAreaContent);
      case 4:
        return await positionTitle(textAreaContent);
      case 5:
        return await positionWordcount(textAreaContent, charLimit);
      default:
        console.error("Invalid stage");
        return null;
    }
  };

  const handleGrowth = async (stage, company, position, hireNotice, coreValues, textAreaContent, question1, question2, question3, charLimit) => {
    switch (stage) {
      case 1:
        return await growthRecommendation(company, position, hireNotice, coreValues);
      case 2:
        return await growthDraft(question1, question2, question3);
      case 3:
        return await growthEnvision(textAreaContent);
      case 4:
        return await growthQuantTitle(textAreaContent);
      case 5:
        return await growthCheer(textAreaContent);
      default:
        console.error("Invalid stage" + stage);
        return null;
    }
  };

  return (
    <div className={styles.container}>
      {isModalOpen && 
      <Modal 
        industry={industry} 
        setIndustry={setIndustry} 
        company={company} 
        setCompany={setCompany} 
        position={position} 
        setPosition={setPosition} 
        hireNotice={hireNotice} 
        setHireNotice={setHireNotice} 
        setCardInfo={setCardInfo}
      />}
      <SideBar 
        isHovered={isHovered}
        setIsHovered={setIsHovered}
        movePage={movePage}
        setModalOpen={setModalOpen}
        signOut={signOut}
      />
      <Menu
        isModalOpen={isModalOpen}
        company={company}
        position={position}
        textAreaContent={textAreaContent}
        handleTextAreaChange={handleTextAreaChange}
        currentCharCount={currentCharCount}
        charLimit={charLimit}
        handleCharLimit={handleCharLimit}
        isInputValuePresent={isInputValuePresent}
        currentStage={currentStage}
        selectedType={selectedType}
        selectedSubType={selectedSubType}
        getData={getData}
      />
      <Feedback
        currentStage={currentStage}
        isTypeSelected={isTypeSelected}
        selectedType={selectedType}
        CATEGORY_TYPES={CATEGORY_TYPES}
        toggleCategoryType={toggleCategoryType}
        handleCategoryType={handleCategoryType}
        isSubTypeSelected={isSubTypeSelected}
        selectedSubType={selectedSubType}
        CATEGORY_SUBTYPES={CATEGORY_SUBTYPES}
        toggleCategorySubType={toggleCategorySubType}
        handleCategorySubType={handleCategorySubType}
        isReady={isReady}
        coreValues={coreValues}
        question1={question1}
        question2={question2}
        question3={question3}
        response={response}
        handleCoreValuesChange={handleCoreValuesChange}
        handleQuestionsChange={handleQuestionsChange}
        getData={getData}
    />
    </div>
  );
}

export default RecruitmentPage;
