import styles from "./HomePage.module.css";
import { useNavigate } from "react-router-dom";
import logo from "./images/logo.svg";
import sample1 from "./images/sample1.svg";
import sample2 from "./images/sample2.svg";
import chat from "./images/chat.svg";
import open from "./images/open.svg";
import signin from "./images/signin.svg";
import signout from "./images/signout.svg";
import search from "./images/search.svg";
import AddCard from './images/AddCard.svg';
import pinTruesvg from "./images/pinTrue.svg";
import pinFalsesvg from "./images/pinFalse.svg";
import deletesvg from "./images/delete.svg"

import React, { useState, useEffect } from 'react';
import { db } from '../../hooks/Firebase.js';
import { doc, addDoc, updateDoc, deleteDoc, collection, query, where, getDocs, Timestamp } from "firebase/firestore";

const HomePage = () => {
  const [cards, setCards] = useState([]);
  const [isLoggedIn, setLogin] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [cardsChanged, setCardsChanged] = useState(false);
  const movePage = useNavigate();

  useEffect(() => {
    checkLoginState();
    getCards();
    sessionStorage.removeItem('cardInfo');
  }, []);

  
  useEffect(() => {
    setCards(prevCards => sortCards(prevCards));
    setCardsChanged(false);
  }, [cardsChanged]);
  
  const getCards = async () => {
    const uid = sessionStorage.getItem('userID');
    const q = query(collection(db, 'cards'), where('author', '==', uid));
    const data = await getDocs(q);
    const cardsArray = data.docs.map(doc => ({ ...doc.data(), id: doc.id }));
    setCards(cardsArray);
    setCardsChanged(true);
  };

  const deleteCard = async (cardId) => {
    if (!cardId) {
      console.error("Invalid card ID");
      return;
    }
    
    if (window.confirm("Are you sure you want to delete this card?")) {
      try {
        const cardRef = doc(db, 'cards', cardId);
        await deleteDoc(cardRef);
  
        const updatedCards = cards.filter(card => card.id !== cardId);
        setCards(updatedCards);
        setCardsChanged(true);
      } catch (error) {
        console.error("Error deleting card:", error);
      }
    }
  };

  const addNewCard = async () => {
    const uid = sessionStorage.getItem('userID');
    if (uid !== null) {
      movePage('/recruitment');
    } else {
      movePage('/login');
    }
  };

  const sortCards = (cards) => {
    const pinnedCards = cards.filter(card => card.isPinned);
    const pinnedSortedCards = [...pinnedCards].sort((a, b) => b.editedDate.toMillis() - a.editedDate.toMillis());
    const unpinnedCards = cards.filter(card => !card.isPinned);
    const unpinnedSortedCards = [...unpinnedCards].sort((a, b) => b.editedDate.toMillis() - a.editedDate.toMillis());
    const sortedCards = [...pinnedSortedCards, ...unpinnedSortedCards];
    return sortedCards;
  };
  
  const togglePin = async (card) => {
    if (!card || !card.id) {
      console.error("Invalid card object or missing card ID");
      return;
    }
    const cardRef = doc(db, 'cards', card.id);
    const updatedCard = { ...card, isPinned: !card.isPinned };
    try {
      await updateDoc(cardRef, updatedCard);
      const updatedCards = cards.map(c => c.id === card.id ? updatedCard : c);
      const sortedCards = sortCards(updatedCards);
      setCards(sortedCards);
      setCardsChanged(true);
    } catch (error) {
      console.error("Error toggling pin:", error);
    }
  };

  const checkLoginState = () => {
    const uid = sessionStorage.getItem('userID');
    if (uid !== null) {
      setLogin(true);
    } else {
      setLogin(false);
    }
  };

  const moveToRecruitPage = (card) => {
    const cardString = JSON.stringify(card);
    sessionStorage.setItem('cardInfo', cardString);
    movePage('/recruitment');
  };

  const signOut = () => {
    sessionStorage.clear();
    movePage('/login');
  };

  return (
    <div className={styles.container}>
      <div className={styles.menu_container}>
        <img className={styles.logo} src={logo} width={176} height={39} />
        <div className={styles.line} />
        <img className={styles.sample1} src={sample1} width={233} height={49} />
        <img className={styles.sample2} src={sample2} width={280} height={187} />
        <div className={styles.bottom_container}>
          <button className={styles.chat} onClick={() => window.open('http://pf.kakao.com/_PfmBG/chat', '_blank')}>
            <img src={chat} width={101} height={25} />
          </button> 
          <button className={styles.open} onClick={() => window.open('https://open.kakao.com/o/soJfnGtf', '_blank')}>
            <img src={open} width={144} height={25} />
          </button>
          {isLoggedIn === true ?
            <button className={styles.sign} onClick={signOut}>
              <img src={signout} width={91} height={25} />
            </button> : 
            <button className={styles.sign} onClick={() => movePage('/login')}>
              <img src={signin} width={78} height={25} />
            </button>
          }
        </div>
      </div>
      <div className={styles.main_container}>
        <div className={styles.search_container}>
          <input className={styles.search} type="text" placeholder="내용 검색하기" value={searchInput} onChange={(e) => setSearchInput(e.target.value)}/>
          <button className={styles.search}>
            <img src={search}/>
          </button>
        </div>
        <div className={styles.card_container}>
          <div className={styles.card}>
            <button className={styles.add_card} onClick={addNewCard}>
              <img src={AddCard} width={104} />
            </button>
          </div>
          {cards
          .filter((card) =>
            searchInput
              ? card.company.toLowerCase().includes(searchInput.toLowerCase()) ||
                card.position.toLowerCase().includes(searchInput.toLowerCase())
              : true
            )
            .map((card) => (
              <div key={card.id} className={styles.card}>
                <button className={styles.card_list_pin} onClick={() => togglePin(card)}>
                {card.isPinned === true ?
                  <img src={pinTruesvg} width={12} /> : 
                  <img src={pinFalsesvg} width={12} />
                }
                </button>
                <button className={styles.card_list_delete} onClick={() => deleteCard(card.id)}>
                  <img src={deletesvg} width={10} />
                </button>
                <button className={styles.card_list} onClick={() => moveToRecruitPage(card)}>
                  <span className={styles.card_list_company}>{card.company}</span>
                  <br/>
                  <span className={styles.card_list_position}>{card.position}</span>
                  <br/>
                  <span className={styles.card_list_questions}>총 1문항</span>
                </button>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default HomePage
